//goals styles
@import 'fonts';

.goals {
    grid-area: goals;
    margin: 0 0 0 1rem;
    background: rgba(211, 211, 211, 0.5);
    border: 2px solid gray;
    border-radius: 1rem;
    padding: 1.5rem;
    transition: height 0.3s;

    @media screen and (max-width: 750px){
        margin: 1rem 0.5rem;
    }

    &__headline {
        font-family: $headline;
        margin: 0;
        letter-spacing: 0.1rem;
        font-size: 2rem;
        color: lightskyblue;
        text-shadow: 1px 1px 0 royalblue, -1px 1px 0 royalblue, 1px -1px 0 royalblue, -1px -1px 0 royalblue;
        border-bottom: 2px solid gray;
        margin-bottom: 1rem;
    }

    &__note {
        font-size: 1.5rem;
        line-height: 1.2;
        margin-bottom: 1rem;
    }

    &__dismiss {
        border: none;
        background: lightcoral;
        color: white;
        border-radius: 0.9rem;
        display: inline-block;
        margin-left: 0.5rem;
    }

    &__form {
        border: 2px solid dodgerblue;
        border-radius: 0.4rem;
        padding: 0.5rem;
        background: lightskyblue;
        display: grid;
        grid-template-areas:
            'label challenges'
            'schedule schedule'
            'input button';
        grid-template-columns: 1fr auto;

        &-label {
            display: block;
            font-size: 1.3rem;
            grid-area: label;
            color: dodgerblue;
            font-weight: 700;
        }

        &-challenges {
            grid-area: challenges;
            border: none;
            background: none;
            color: dodgerblue;
            font-weight: 700;
        }

        &-input {
            grid-area: input;
            margin: 0 0.5rem 0.5rem 0;
            padding: 0.5rem;
            font-size: 1.5rem;
            border-radius: 0.5rem;
            border: none;
            height: 100%;
            box-sizing: border-box;

            &::placeholder {
                color: lightgray;
            }
        }

        &-button {
            font-weight: 700;
            background: dodgerblue;
            border: none;
            border-radius: 0.5rem;
            color: white;
            padding: 0.5rem 1rem;
            transition: color 0.3s, background-color 0.3s;

            &:hover {
                color: dodgerblue;
                background-color: white;
            }
        }

        &-schedule {
            grid-area: schedule;
            display: flex;
            justify-content: space-between;
            margin-bottom: 0.5rem;

            &-day {
                display: inline-block;
                margin-right: 0.5rem;
                position: relative;
                cursor: pointer;
            }

            &-label {
                font-weight: 700;
                font-size: 1.7em;
                color: white;
                background: dodgerblue;
                width: 2em;
                display: inline-block;
                line-height: 2em;
                text-align: center;
                border-radius: 50%;
                opacity: 0.25;
                transition: color 0.3s, background-color 0.3s, opacity 0.3s;

                &:hover {
                    background-color: white;
                    color: dodgerblue;
                }
            }

            &-checkbox {
                position: absolute;
                opacity: 0;
                pointer-events: none;

                &:checked ~ .goals__form-schedule-label {
                    opacity: 1;
                }
            }
        }
    }

    &__entry {
        border: 2px solid gray;
        border-radius: 0.5rem;
        margin-top: 1rem;
        padding: 0.5rem;
        background: lightgray;
        display: grid;
        grid-template-columns: auto 1fr auto;
        grid-template-areas:
            'schedule schedule schedule'
            'check challenge delete'
            'text text text';

        &:nth-of-type(7n+1) {
            background: mistyrose;
            border-color: salmon;

            li {
                background: salmon;
            }
        }
        &:nth-of-type(7n+2) {
            background: seashell;
            border-color: orange;

            li {
                background: orange;
            }
        }
        &:nth-of-type(7n+3) {
            background: ivory;
            border-color: gold;

            li {
                background: gold;
            }
        }
        &:nth-of-type(7n+4) {
            background: honeydew;
            border-color: limegreen;

            li {
                background: limegreen;
            }
        }
        &:nth-of-type(7n+5) {
            background: azure;
            border-color: skyblue;

            li {
                background: skyblue;
            }
        }
        &:nth-of-type(7n+6) {
            background: aliceblue;
            border-color: cornflowerblue;

            li {
                background: cornflowerblue;
            }
        }
        &:nth-of-type(7n+7) {
            background: lavender;
            border-color: mediumpurple;

            li {
                background: mediumpurple;
            }
        }

        &-check,
        &-delete {
            font-family: $headline;
            border: 2px solid;
            border-radius: 0.3rem;
            color: white;
            padding: 0.7rem;
            letter-spacing: 0.1rem;
            transition: background-color 0.3s;

            &:hover {
                background: white;
            }
        }

        &-check {
            grid-area: check;
            border-color: darkgreen;
            background: limegreen;
            text-shadow: 1px 1px 0 darkgreen, -1px 1px 0 darkgreen, 1px -1px 0 darkgreen, -1px -1px 0 darkgreen;


            .true & {
                pointer-events: none;
                border-color: darkgray;
                background: gray;
                text-shadow: 1px 1px 0 darkgray, -1px 1px 0 darkgray, 1px -1px 0 darkgray, -1px -1px 0 darkgray;
            }
        }

        &-delete {
            grid-area: delete;
            border-color: darkred;
            background: crimson;
            text-shadow: 1px 1px 0 darkred, -1px 1px 0 darkred, 1px -1px 0 darkred, -1px -1px 0 darkred;
        }

        &-text {
            grid-area: text;
            padding: 0 1rem;
            font-size: 1.6rem;
            text-align: center;
        }

        &-challenge {
            grid-area: challenge;
            font-family: $headline;
            letter-spacing: 0.1rem;
            font-size: 1.6rem;
            text-align: center;
            padding: 0.7rem 0;
        }

        &.true {
            opacity: 0.5;
            position: relative;

            &::before{
                display:block;
                position:absolute;
                content: 'Done';
                font-family: $titleText;
                color: red;
                font-size: 2.5rem;
                font-weight: 700;
                padding: 0 0.5rem;
                border: 4px solid red;
                border-radius: 0.5rem;
                background: white;
                left: 5%;
                top: 10%;
                transform:rotate(-20deg);
            }
        }
    }

    &__schedule {
        grid-area: schedule;
        padding: 0.5rem;
        margin: 0;
        list-style: none;
        display: flex;
        justify-content: space-between;

        &-day {
            font-size: 1.5em;
            font-weight: 700;
            color: white;
            background: lightgray;
            line-height: 1.8em;
            width: 1.9em;
            text-align: center;
            border-radius: 50%;
            opacity: 0.25;

            &.true {
                opacity: 1;
            }
        }
    }
}