//font file
@import url('https://fonts.googleapis.com/css2?family=Fredoka+One&family=Luckiest+Guy&family=Nunito:wght@300;400;700&display=swap');

/*
font-family: 'Fredoka One', cursive;
font-family: 'Luckiest Guy', cursive;
font-family: 'Nunito', sans-serif;
*/

$titleText: 'Luckiest Guy', cursive;

$headline: 'Fredoka One', cursive;

$bodyCopy: 'Nunito', sans-serif;